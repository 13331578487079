import ImgMenu from '../../assets/images/logo_ls_horizontal.png'

export default function Menu () {

  return (
    <header className="header_section" id="page-top">
      <div className="container">
        <nav className="row navbar navbar-expand-lg custom_nav-container ">
          <a className="col navbar-brand marca" href="#welcome">
            <div className="logo_box">
              <img src={ImgMenu} alt="Linkservice" />
            </div>
          </a>
          <div className="col col-mx-auto"></div>

          <button className="navbar-toggler col-2 mr-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="col col-lg-8 col-sm-8 col-md-8 collapse navbar-collapse" id="navbarSupportedContent">
            <ol className="navbar-nav">
              <li className="active">
                <a className="btn btn-nav" href="#problema">El problema</a>
              </li>
              <li>
                <a className="btn btn-nav" href="#solucion">La solucion</a>
              </li>
              <li>
                <a className="btn btn-nav" href="#ventaja">Ventaja competitiva</a>
              </li>
              <li>
                <a className="btn btn-nav" href="#nosotros">Sobre nosotros</a>
              </li>
              <li>
                <a className="btn btn-nav" href="#contacto">Contacto</a>
              </li>
            </ol>
          </div>
        </nav>
      </div>
  </header>
)
}