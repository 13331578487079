export default function Welcome () {

  return <div className='container gray-background'>
    <div className="only-on-large">
      <div className="container row-6 container welcome_section">
        <div className="col-md-5">
          
        </div>
        <div className="col-md-7 col-sm-12 col-xs-12 justify-content-center fixed_company-detail">
          <div className="titulo titulo-welcome">
            LINKSERVICE
          </div>
          <div className="subtitulo-welcome">
            Fidelizamos a los clientes, ordenamos el taller
          </div>
        </div>
      </div>
    </div>
  </div>
}