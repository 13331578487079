import { useState } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";

export default function Footer () {

  const sender = axios.create(
    {
      baseURL: "https://ls-website-mailer-oijc3klhpa-rj.a.run.app/api",
      timeout: 10000,
      headers: {'Content-Type': 'application/json', 'Accept':'*/*'}
    }
  )
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [failModalShow, setFailModalShow] = useState(false);
  const [datos, setDatos] = useState({
    from: '',
    subject: '',
    message: '',
  })

  const handleInputChange = (event) => {
    setDatos({
        ...datos,
        [event.target.name] : event.target.value
    })
  }

  function limpiarFormulario() {
    setDatos({
      from: '',
      subject: '',
      message: ''
    })
  }

  function MessageSuccess(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <h4>Gracias!</h4>
          <p>
          Su mensaje ha sido enviado, le responderemos a la brevedad
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} className="btn btn-form titulo">Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  function MessageFail(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <h4>Error!</h4>
          <p>
          Su mensaje no ha sido enviado, Vuelva a intentar en unos segundos
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} className="btn btn-danger titulo">Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const enviarDatos = (event) => {
    console.log('payload:->', datos.from, datos.subject, datos.message)
    
    setBtnDisabled(true)
    event.preventDefault()
    sender.post("/new-message",
    JSON.stringify(datos)
    )
    .then(function (response) {
      console.log(response);
      limpiarFormulario()
      setSuccessModalShow(true)
    })
    .catch(function (error) {
      console.error("Fail!--->: ",error);
      setFailModalShow(true)
    });
  }

  return (
  <div className="gray-background" id="nosotros">
    <div className='container only-on-large'>
      <div className="site-footer">
        <MessageSuccess
          show={successModalShow}
          onHide={() => {setSuccessModalShow(false); setBtnDisabled(false)}}
        />
        <MessageFail
          show={failModalShow}
          onHide={() => {setFailModalShow(false); setBtnDisabled(false)}}
        />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6" id="nosotros">
            <h6>NOSOTROS</h6>
            <p className="text-nosotros">
            LinkService es nuestra solución para fidelizar a sus clientes, con una inversión prácticamente nula (esquema de pago por uso) y que permite obtener información sobre los clientes y definir estrategias comerciales en función a ello, al mismo tiempo que permite administrar los talleres. LinkService tiene la posibilidad de correr directamente en el teléfono de los clientes para monitorear la proximidad de un mantenimiento (y coordinarlo en tiempo real), administrar la operación del taller (estados del servicio, aprobaciones de presupuestos, gestión de garantías, etc.), realizar acciones comerciales en función a diversos parámetros o brindar asistencia mecánica, entre algunas de las funcionalidades disponibles.            </p>
          </div>
          <div className="col-sm-12 col-md-6" id="contacto">
            <h6>Contacto</h6>
            <div className="container">
              <form className="row g-2" id="contactForm" onSubmit={enviarDatos}>
                <div className="col-md-6">
                  <input type="email" className="form-control" id="from" name="from" onChange={handleInputChange} value={datos.from} placeholder="Email" maxLength="40"/>
                </div>
                <div className="col-md-6">
                  <input type="text" className="form-control" id="subject" name="subject" onChange={handleInputChange} value={datos.subject} placeholder="Asunto" maxLength="150"/>
                </div>
                <div className="col-12">
                  <textarea className="col-12 form-control" rows="5" maxLength="350" id="message" name="message" onChange={handleInputChange} value={datos.message} placeholder="¿En que podemos ayudarle?" style={{resize: "none"}}></textarea>
                </div>
                
                <div className="col-12">
                  <Button disabled={btnDisabled} type="submit" className="btn btn-form titulo">Enviar</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <hr/>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <div className="copyright-text">
              Copyright &copy; {new Date().getFullYear()} Todos los derechos reservados.
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <ol className="social-icons">
              <li><a className="email" href="mailto:info@linkservice.com.ar" target="_blank" rel="noreferrer"><i className="bi bi-envelope"></i></a></li>
              <li><a className="linkedin" href="https://www.linkedin.com/company/linkservice/" target="_blank" rel="noreferrer"><i className="bi bi-linkedin"></i></a></li>   
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>


  </div>
)
}