import { Paid, ContactSupport, Outlet, ThumbDown, SearchOff } from '@mui/icons-material';

export default function ElProblema () {

  return <div id="problema" style={{backgroundColor: "#ff9801"}}>
    <div className='orange-background'>
    <div className="col-12 problema-titulo titulo">
              El problema
          </div>
    </div>
    <div className='orange-background '>
      <div className='mx-auto seccion-subtitulo titulo'>
        9 de cada 10 clientes no utiliza el servicio de mantenimiento oficial o sólo lo utiliza durante el período de garantía
      </div>
    </div>
    <div className='white-background'>
      <div className='container only-on-large'>
        <div className="row pb-5 px-4">
          <div className="col-sm-12 mb-2 px-5 problema-card">
              <Paid className='icono-razones'/>
            <div className="col-12 titulo-problema" >
              Costo
            </div>
            <div className="px-2 mt-3">
              Creencia instalada: servicio oficial = precio más alto que el del mercado
            </div>
          </div>
          <div className="col-sm-12 mb-2 px-5 problema-card">
              <Outlet className='icono-razones'/>
            <div className="col-12 titulo-problema" >
            Transparencia
            </div>
            <div className="px-2 mt-3">
              Baja visibilidad del trabajo. Desconfianza.
            </div>
          </div>
          <div className="col-sm-12 mb-2 px-5 problema-card">
              <ContactSupport className='icono-razones'/>
            <div className="col-12 titulo-problema" >
            Oportunidad
            </div>
            <div className="px-2 mt-3">
              Olvido o desconocimiento del plan de mantenimiento del vehículo
            </div>
          </div>
          <div className="col-sm-12 mb-2 px-5 problema-card">
              <ThumbDown className='icono-razones'/>
            <div className="col-12 titulo-problema" >
            Relacionamiento
            </div>
            <div className="px-2 mt-3">
              Ineficiencia en la administración de los turnos de los talleres
            </div>
          </div>
          <div className="col-sm-12 mb-2 px-5 problema-card">
              <SearchOff className='icono-razones'/>
            <div className="col-12 titulo-problema" >
            Valor Agregado
            </div>
            <div className="px-2 mt-3">
              Imposibilidad de ponderar el diferencial de un taller oficial
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  




}