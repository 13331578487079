export default function Ventaja() {

  return <div id="ventaja"  style={{backgroundColor: "#ff9801"}}>
  <div className='orange-background '>
      <div className="row">
        <div className="col ventaja-titulo titulo">
            ventaja
        </div>
      </div>
  </div>
  <div className='orange-background '>
      <div className="row">
        <div className="col seccion-subtitulo titulo">
          Nuestras principales diferencias son
        </div>
      </div>
  </div>
  <div className='white-background'>
    <div className="container-fluid only-on-large">
      <div className="row pb-5 px-4">
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Agilidad
          </div>
          <div className="px-2">
          Capacidad de implementación en un plazo de dos semanas
          </div>
        </div>
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Flexibilidad
          </div>
          <div className="px-2">
          Solución modular adaptable a cada cliente
          </div>
        </div>
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Experiencia
          </div>
          <div className="px-2">
          Contamos con instancias de la solución implementadas en Argentina y Chile
          </div>
        </div>
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Equipo
          </div>
          <div className="px-2">
          Equipo altamente motivado con amplia experiencia en la tecnología y la industria
          </div>
        </div>
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Modelo de riesgo compartido
          </div>
          <div className="px-2">
          Esquema de servicio variable con costo basado en el éxito del proyecto
          </div>
        </div>
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Escalabilidad
          </div>
          <div className="px-2">
          Solución SaaS fácilmente escalable
          </div>
        </div>
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Simplicidad
          </div>
          <div className="px-2">
          Sin necesidad de instalación de dispositivos adicionales
          </div>
        </div>
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Sinergias
          </div>
          <div className="px-2">
          Solución fácilmente integrable con otros sistemas
          </div>
        </div>
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Competencia
          </div>
          <div className="px-2">
          Poca oferta de soluciones con características similares en la región
          </div>
        </div>
        <div className="ventaja-card col-sm-12 mb-2">
          <div className="titulo-item-ventaja titulo" >
          Tecnología
          </div>
          <div className="px-2">
          Capacidad de integrarnos con dispositivos telemáticos
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
}