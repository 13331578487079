import './App.scss';
import ElProblema from './components/body/ElProblema';
import LaSolucion from './components/body/LaSolucion';
import Ventaja from './components/body/Ventaja';
import Welcome from './components/body/Welcome';
import Footer from './components/footer/Footer';
import Menu from './components/header/Menu';
import ScrollButton from 'react-scroll-button'

function App() {
  return (
    <div className="App">
      <Menu/>
      <Welcome/>
      <ElProblema/>
      <LaSolucion/>
      <Ventaja/>
      <Footer/>
      <ScrollButton
        targetId={'page-top'} 
        behavior={'smooth'} 
        buttonBackgroundColor={'#ff9801'}
        buttonColor={'#26272b'}
        iconType={'angle-up'}
      />
    </div>
  );
}

export default App;
