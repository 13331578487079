import { Carousel } from "react-bootstrap";
import ImgPostventa from '../../assets/images/Slider1Foto_posventa.jpg'
import ImgInformacion from '../../assets/images/Slider2Foto_Informacion.jpg'
import ImgMarketing from '../../assets/images/Slider3Foto_Marketing.jpg'
import ImgWebadmin from '../../assets/images/Slider4Foto_WebAdmin.jpg'
import ImgCliente from '../../assets/images/Slider5Foto_Cliente.jpg'

export default function LaSolucion () {
  const slideTime = 1000 * 6;
  return <div id="solucion">
    <div className='orange-background'>
      <div className="row" style={{marginLeft: 0, marginRight: 0}}>
        <div className="col solucion-titulo titulo">
            La Solución
        </div>
      </div>
    </div>
    <div className='gray-background carousell-class'>
      <Carousel className="container" pause="false">
        <Carousel.Item interval={slideTime}>
          <img
            className="d-block w-100"
            src={ImgPostventa}
            alt="First slide"
          />
          <div className="col-12">
            <Carousel.Caption className="solucion-postventa">
              <div className="item-titulo-1 titulo">Gestión de la Posventa</div>
              <ul className="item-texto-1">
                <li>Registro de viajes y el envío de avisos de mantenimiento</li>
                <li>Cotización y planificación de mantenimientos</li>
                <li>Solicitud de turno ad hoc</li>
                <li>Recordatorio de cita</li>
                <li>Calificación de servicio y notificación de calificación negativa</li>
                <li>Historial de servicios realizados</li>
              </ul>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={slideTime}>
          <img
            className="d-block w-100"
            src={ImgInformacion}
            alt="Second slide"
          />
          <div className="col-12">
            <Carousel.Caption className="solucion-informacion">
              <div className="item-titulo-2 titulo">Información</div>
                <ul className="item-texto-2">
                  <li>Tutoriales de información del vehículo y seguridad vial</li>
                  <li>Información sobre la red de concesionarios oficiales</li>
                </ul>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={slideTime}>
          <img
            className="d-block w-100"
            src={ImgMarketing}
            alt="Third slide"
          />
          <div className="col-12">
          <Carousel.Caption className="solucion-marketing">
            <div className="item-titulo-3 titulo">Marketing</div>
              <ul className="item-texto-3">
                <li>Promociones segmentadas por modelo de vehículo del cliente</li>
                <li>Posibilidad de ofrecer promociones y accesorios durante el proceso de planificación del mantenimiento</li>
                <li>Campañas especiales de servicios</li>
              </ul>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={slideTime}>
          <img
            className="d-block w-100"
            src={ImgWebadmin}
            alt="Fourth slide"
          />
          <div className="col-12">
            <Carousel.Caption className="solucion-webadmin">
              <div className="item-titulo-4 titulo">Sistema Web Administrador</div>
              <ul className="item-texto-4">
                <li>Calendario con detalle de turnos por día y por cliente</li>
                <li>Posibilidad de enviar notificaciones push a grupos de clientes</li>
                <li>Agendamiento de turnos</li>
                <li>Administración de órdenes de reparación y garantías</li>
                <li>Ubicación del vehículo en tiempo real</li>
              </ul>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={slideTime}>
          <img
            className="d-block w-100"
            src={ImgCliente}
            alt="Fifth slide"
          />
          <div className="col-12">
            <Carousel.Caption className="solucion-cliente">
              <div className="item-titulo-5 titulo">Relación con el Cliente</div>
              <ul className="item-texto-5">
                <li>Notificación de estado del servicio</li>
                <li>Envío de fotos y videos del servicio</li>
                <li>Envío de presupuesto para aprobación y/o factura por correo electrónico</li>
                <li>Servicios de asistencia</li>
              </ul>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  </div>

}
